import { reactive } from 'vue'

export const store = reactive({
  /** @type {String} */ 
  deckFilter: "",

  /** @type {Boolean} */ 
  imagesVisible: true,

  setDeckFilter(deck) {
    store.deckFilter = deck;
  }
})

export function toggleImagesVisible() {
  store.imagesVisible = !store.imagesVisible;
}