<template>
  <div class="cardView">
    <div class="row cardName">
      <div class="column">
        <div v-for="card in this.threeCardColumns[0]" :key="card.CardName">
          <a :href="card.ScryfallURL" target="_blank"><img :src="card.ImageURL" :title="card.Deck"></a>
          <span v-if="!deckFilter" >{{ card.Deck }} <i>({{ card.Set }})</i></span>
        </div>
      </div>
      <div class="column">
        <div v-for="card in this.threeCardColumns[1]" :key="card.CardName">
          <a :href="card.ScryfallURL" target="_blank"><img :src="card.ImageURL" :title="card.Deck"></a>
          <span v-if="!deckFilter" >{{ card.Deck }} <i>({{ card.Set }})</i></span>
        </div>
      </div>
      <div class="column">
        <div v-for="card in this.threeCardColumns[2]" :key="card.CardName">
          <a :href="card.ScryfallURL" target="_blank"><img :src="card.ImageURL" :title="card.Deck"></a>
          <span v-if="!deckFilter" >{{ card.Deck }} <i>({{ card.Set }})</i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import proxies from '../assets/proxies.json'
import { store } from '@/store'

  export default {
    name: 'CardList',
    data() {
      return {
        proxyCards: proxies,

        showArtists: false,

        cardsWithImages: [],
        threeCardColumns: [],
      }
    },
    mounted() {
      this.buildCards()
    },
    computed: {
      deckFilter() {
        return store.deckFilter
      },
      imagesVisible() {
        return store.imagesVisible
      },
      numberOfCards() {
        return this.cards.length
      },
      showArtistsLabel() {
        return this.showArtists ? "Switch back to Proxies" : "Show Owned Cards with Artists at MagicCon 24"
      }
    },
    methods: {
      buildCards() {
        this.threeCardColumns = [
          [],
          [],
          []
        ]
        this.cardsWithImages = []

        this.removeFilteredCards(this.proxyCards)
        this.computeThreeCardColumns()
      },
      computeThreeCardColumns() {
        for (let i = 0; i < this.cardsWithImages.length; i++) {
          let col = i % 3

          this.threeCardColumns[col].push(this.cardsWithImages[i])
        }
      },
      removeFilteredCards(cards) {
        cards.forEach(card => {
          if ((card.Deck == this.deckFilter || !this.deckFilter) && card.ImageURL != "") {
            this.cardsWithImages.push(card)
          }
        });
      },
      removeMissingImageCards(cards) {
        cards.forEach(card => {
          if (card.ImageURL != "") {
            this.cardsWithImages.push(card)
          }
        });
      }
    },
    watch: {
      deckFilter() {
        this.buildCards()
      },
      showArtists() {
        this.buildCards()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .cardName {
    font-size: 2rem
  }

  @media only screen and (max-width: 500px) {
    .cardName {
      font-size: .5rem
    }
  }

 .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 31%;
  max-width: 31%;
  padding: 1%;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}
</style>