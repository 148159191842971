<template>
  <div class="deckFilter">
    <div
      class="deckFilterHeader"
      @click="this.toggleShowDeckFilter()"
    >
      Deck Filter 


      <span v-if="deckFilter">
        <button @click="this.clearDeckFilter()">
          <i>reset</i>
        </button>
      </span>


      <span v-if="deckFilter">
        {{ this.deckFilter }}
      </span>
    </div>
    
    <div v-if="showDeckFilter">
      <div>
        <button 
          v-for="deck in decks" 
          :key="deck" 
          class="deckList"
          @click="this.updateDeckFilter(deck)"
        >{{ deck }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import proxies from '../assets/proxies.json'
import { store } from '@/store'

  export default {
    name: 'DeskFilter',
    data() {
      return {
        decks: [],
        deckFilter: "",
        showDeckFilter: false,
      }
    },
    mounted() {
      console.log('started')
      console.log(this.cards)
      this.deckFilter = store.deckFilter;

      this.parseDecks()
    },
    computed: {
      showArtistsLabel() {
        return this.showArtists ? "Switch back to Proxies" : "Show Owned Cards with Artists at MagicCon 24"
      }
    },
    methods: {
      clearDeckFilter() {
        this.deckFilter = null
        store.deckFilter = this.deckFilter;
      },
      parseDecks() {
        console.log("asdf")
        console.log(proxies)
        for (const card of proxies) {
          console.log(card.Deck);

          if (this.decks.indexOf(card.Deck) === -1) {
            this.decks.push(card.Deck);
            console.log("Added deck: ", card.Deck)
          }
        }

        this.decks.sort()
      },
      toggleShowDeckFilter() {
        this.showDeckFilter = !this.showDeckFilter;
      },
      updateDeckFilter(deck) {
        this.deckFilter = deck
        store.deckFilter = this.deckFilter;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cardName {
  font-weight: bold
}

.cards {
  font-size:2em;
}

.deckFilter {
  border-width: 4px;
  border-style: dotted;
}

.deckList {
  margin-right: 20px;
}

.deckFilterTitle {
  padding-left: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .cards {
    font-size:.85em;
  }

  .deckFilter {
    margin-right: 5px;
  }

  h3 {
    font-size: 14px;
  }

  button {
    font-size: 10px;
  }
}
</style>
