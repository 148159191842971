<template>
  <div v-for="card in cards" :key="card.CardName+card.Deck" class="cards">
    <a :href="card.ScryfallURL" target="_blank"><span class="cardName">{{ card.CardName }}</span></a> ({{ card.Price }} / {{ card.Foil }}) <span v-if="!deckFilter">for <b>{{ card.Deck }}</b></span>
  </div>
  
</template>

<script>
import proxies from '../assets/proxies.json'
import { store } from '@/store'

  export default {
    name: 'CardList',
    data() {
      return {
        cards: proxies,
        showArtists: false
      }
    },
    mounted() {
      console.log('started')
      console.log(this.cards)

      this.filterCards()
    },
    computed: {
      deckFilter() {
        console.log("Getting deckfilter: " + store.deckFilter)
        return store.deckFilter
      },
      showArtistsLabel() {
        return this.showArtists ? "Switch back to Proxies" : "Show Owned Cards with Artists at MagicCon 24"
      }
    },
    methods: {
      filterCards() {
        console.log("filtering cards")
        this.cards = []

        if (this.deckFilter) {
          for (const proxy in proxies) {
            let currentCard = proxies[proxy]

            if (currentCard.Deck == store.deckFilter) {
              this.cards.push(currentCard)
            }
          }
        } else {
          this.cards = proxies
        }
      }
    },
    watch: {
      deckFilter() {
        this.filterCards()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cardName {
  font-weight: bold
}

/* .cards {
  font-size:2em;
} */

@media only screen and (max-width: 500px) {
  .cards {
    font-size:.85em;
  }
}
</style>
