<template>
  <div class="header">
    <!-- <img alt="Vue logo" src="../assets/barrebre.png" class="logo"> -->
    <h1>Proxy Tracker</h1>
  </div>
  
</template>
<script>

export default {
  name: 'HeaderView'
}
</script>
<style>
.header {
  font-size: 18px;
  height: 10%;
}
</style>