<template>
  <HeaderView />
  <DeckFilter />

  <ImageToggle />
  <CardList v-if="!imagesVisible"/>
  <CardView v-if="imagesVisible"/>
</template>

<script>
import CardList from './components/CardList.vue';
import CardView from './components/CardView.vue';
import DeckFilter from './components/DeckFilter.vue';
import HeaderView from './components/HeaderView.vue';
import ImageToggle from './components/ImageToggle.vue';
import { store } from '@/store'

export default {
  name: 'App',
  components: {
    CardList,
    CardView,
    DeckFilter,
    HeaderView,
    ImageToggle
  },
  computed: {
    imagesVisible() {
      return store.imagesVisible
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1 {
  margin: 10px;
}

.logo {
  width: 50%;
}

@media only screen and (max-width: 500px) {
  .logo {
    width: 90%;
  }
}

</style>
